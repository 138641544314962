import React from 'react';

class InlineSVG extends React.Component {
  constructor (props) { 
    super(props);

    this.state = { 
      svgData: "",
      classes: this.props.classes
    };
  }

  componentDidMount() {
    fetch(this.props.url).then(response => response.text())
    .then(data => {
      this.setState({ svgData: data});
    });
  }

  render() {
    return <span className={this.state.classes} dangerouslySetInnerHTML={{ __html: this.state.svgData }} ></span>; 
  }
}

export default InlineSVG;
