import React, {Component} from 'react'
import Rellax from 'rellax'

import Shape from './shape'
class Shapes extends Component {

  componentDidMount() {
    this.rellax = new Rellax('.rellax')
  }

  render() {

    return (
      <div className="kb__shapes">
        <div className="rellax shape--semi-circle" data-rellax-speed="1">
          <Shape shape="Semi Circle"/>
        </div>
        <div className="rellax shape--circle" data-rellax-speed="2">
          <Shape shape="Circle"/>
        </div>
        <div className="rellax shape--rectangle" data-rellax-speed="3">
          <Shape shape="Rectangle"/>
        </div>
        <div className="rellax shape--triangle" data-rellax-speed="4">
          <Shape shape="Triangle"/>
        </div>
      </div>    
    );
  }
}

export default Shapes
