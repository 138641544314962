import React from 'react'
import InlineSVG from './inlineSVG'
import SemiCircle from '../images/shape-semi-circle.svg'
import Circle from '../images/shape-circle.svg'
import Rectangle from '../images/shape-rectangle.svg'
import Triangle from '../images/shape-triangle.svg'

import './shape.scss'


const Shape = (props) => {

  if(!props) return null;

  if(props.shape === "None") return null;
      
  return(
    <>
    {props.shape === "Semi Circle" &&
      <InlineSVG url={SemiCircle} classes="shape"/>
    }

    {props.shape === "Circle" &&
      <InlineSVG url={Circle} classes="shape" />
    }

    {props.shape === "Rectangle" &&
      <InlineSVG url={Rectangle} classes="shape" />
    }

    {props.shape === "Triangle" &&
      <InlineSVG url={Triangle} classes="shape" />
    }
    </>
  )

}

export default Shape